import React, { useState, useEffect } from "react";
import Experience from "../components/Experience";
import { useLanguage } from "../context/LanguageContext";
import "./css/Experiences.css";

const Experiences = () => {
  const { language } = useLanguage();
  const [experiences, setExperiences] = useState([]);

  useEffect(() => {
    fetch("/language-mapping.json")
      .then((response) => response.json())
      .then((data) => {
        if (data[language] && data[language].experiences) {
          setExperiences(data[language].experiences);
        }
      });
  }, [language]);

  return (
    <div className="experiences-page-container">
      {experiences.map((experience) => (
        <Experience
          key={experience.id}
          role={experience.role}
          company={experience.company}
          location={experience.location}
          period={experience.period}
          details={experience.details}
          icon={experience.icon}
        />
      ))}
    </div>
  );
};

export default Experiences;
