import React from "react";
import "./css/Project.css";

const Project = ({ image, title, description, technologies, date, links }) => {
  return (
    <div className="project-container">
      <img src={image} alt={title} className="project-image" loading="lazy" />
      <div className="project-info">
        <h2 className="project-title">{title}</h2>
        <p className="project-description">{description}</p>
        <p className="project-date">{date}</p>
        <div className="project-technologies">
          {technologies.map((tech, index) => (
            <img
              key={index}
              src={tech.icon || "/logo400.png"}
              alt={tech.text}
              title={tech.text}
              className="technology-icon"
              loading="lazy"
            />
          ))}
        </div>
        <div className="project-links">
          {links.map((link, index) => (
            <a
              key={index}
              href={link.url}
              className="project-link"
              target="_blank"
              rel="noopener noreferrer"
            >
              {link.text}
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Project;
