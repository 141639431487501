import React, { useState, useEffect } from "react";
import { useLanguage } from "../context/LanguageContext";
import "./css/Contact.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Contact = () => {
  const { language } = useLanguage();
  const [contactData, setContactData] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    fetch("/language-mapping.json")
      .then((response) => response.json())
      .then((data) => {
        if (data[language] && data[language].contact) {
          setContactData(data[language].contact);
        }
      });
  }, [language]);

  const githubHandle = contactData.github
    ? contactData.github.split("/").pop()
    : "";
  const linkedInHandle = contactData.linkedin
    ? contactData.linkedin.split("/").pop()
    : "";
  const emailHandle = contactData.email ? contactData.emailAddress : "";

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    const { name, surname, email, message } = event.target.elements;
    if (validateInput(name.value, surname.value, email.value, message.value)) {
      try {
        const response = await fetch(
          "https://x922ognlk8.execute-api.eu-central-1.amazonaws.com/prod/contact",
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              name: name.value,
              surname: surname.value,
              email: email.value,
              message: message.value,
            }),
          }
        );
        if (response.ok) {
          toast.success(`${contactData.successMessage} 🎉`, {
            theme: "dark",
            style: { background: "#494949", color: "#f0f0f0" },
            progressStyle: { background: "#494949", color: "#f0f0f0" },
            iconTheme: { primary: "#f0f0f0", secondary: "#494949" },
            fontFamily: "Montserrat",
          });
          setIsSubmitting(false);
          event.target.reset();
        } else {
          toast.error(`${contactData.failureMessage} 😞`, {
            theme: "dark",
            style: { background: "#494949", color: "#f0f0f0" },
            progressStyle: { background: "#f0f0f0" },
            iconTheme: { primary: "#f0f0f0", secondary: "#494949" },
            fontFamily: "Montserrat",
          });
          setIsSubmitting(false);
        }
      } catch (error) {
        console.error("Submission error:", error);
      }
    } else {
      alert("Invalid input");
    }
    function validateInput(name, surname, email, message) {
      return (
        typeof name === "string" &&
        name.length <= 256 &&
        typeof surname === "string" &&
        surname.length <= 256 &&
        typeof email === "string" &&
        email.length <= 256 &&
        email.includes("@") &&
        typeof message === "string" &&
        message.length <= 4096
      );
    }
  };

  return (
    <div className="contact-container">
      <ToastContainer />
      <div className="contact-socials">
        <a
          href={contactData.github}
          target="_blank"
          rel="noopener noreferrer"
          className="social-link"
        >
          <i className="fab fa-github"></i>/{githubHandle}
        </a>
        <a
          href={contactData.linkedin}
          target="_blank"
          rel="noopener noreferrer"
          className="social-link"
        >
          <i className="fab fa-linkedin-in"></i>/{linkedInHandle}
        </a>
        <a href={`mailto:${emailHandle}`} className="social-link">
          <i className="fas fa-envelope"></i>/{emailHandle}
        </a>
      </div>
      <form className="contact-form" onSubmit={handleSubmit}>
        <div className="form-row">
          <input
            type="text"
            placeholder={contactData.name}
            name="name"
            required
          />
          <input
            type="text"
            placeholder={contactData.surname}
            name="surname"
            required
          />
        </div>
        <input
          type="email"
          placeholder={contactData.email}
          name="email"
          required
        />
        <textarea
          placeholder={contactData.message}
          name="message"
          required
        ></textarea>
        <button type="submit" disabled={isSubmitting}>
          {isSubmitting ? "Submitting..." : contactData.submit}
        </button>
      </form>
    </div>
  );
};

export default Contact;
