import React, { useState, useEffect } from "react";
import { useLanguage } from "../context/LanguageContext";
import "./css/Footer.css";

const Footer = () => {
  const { language } = useLanguage();
  const [content, setContent] = useState({});

  useEffect(() => {
    fetch("/language-mapping.json")
      .then((response) => response.json())
      .then((data) => setContent(data));
  }, [language]);

  const currentYear = new Date().getFullYear();
  const footerContent = content[language]?.footer || {};
  const copyrightText = footerContent?.copyright?.replace(
    "{year}",
    currentYear
  );
  const location = footerContent?.location;

  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="social-links">
          <a
            href={footerContent.github}
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-github"></i>
          </a>
          <a
            href={footerContent.linkedin}
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-linkedin-in"></i>
          </a>
          <a href={footerContent.email}>
            <i className="fas fa-envelope"></i>
          </a>
        </div>
      </div>
      <div className="copy-right">{copyrightText}</div>
      <div className="location">{location}</div>
    </footer>
  );
};

export default Footer;
