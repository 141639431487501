import React, { useState, useEffect } from "react";
import Project from "../components/Project";
import { useLanguage } from "../context/LanguageContext";
import "./css/Projects.css";
const Projects = () => {
  const { language } = useLanguage();
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    fetch("/language-mapping.json")
      .then((response) => response.json())
      .then((data) => {
        if (data[language] && data[language].projects) {
          setProjects(data[language].projects);
        }
      });
  }, [language]);

  return (
    <div className="projects-page-container">
      {projects.map((project) => (
        <Project
          key={project.id}
          image={project.image}
          title={project.title}
          date={project.date}
          description={project.description}
          technologies={project.technologies}
          links={project.links}
        />
      ))}
    </div>
  );
};

export default Projects;
