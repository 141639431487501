import React, { useState, useEffect } from "react";
import { useLanguage } from "../context/LanguageContext";
import "./css/Me.css";

const Me = () => {
  const { language } = useLanguage();
  const [content, setContent] = useState({});

  useEffect(() => {
    fetch("/language-mapping.json")
      .then((response) => response.json())
      .then((data) => setContent(data[language]?.me));
  }, [language]);

  return (
    <div className="me-page">
      <div className="text-content">
        <h1>{content.header}</h1>
        {content.paragraphs?.map((paragraph, index) => (
          <p key={index} dangerouslySetInnerHTML={{ __html: paragraph }}></p>
        ))}
        <button
          className="resume-button"
          onClick={() => window.open(content.buttonLink, "_blank")}
        >
          {content.buttonText}
          <i className="fas fa-external-link-alt" aria-hidden="true"></i>
        </button>
      </div>
      <div className="image-content">
        <img
          src={content.imageSrc}
          alt="Ferhat Korkmaz"
          className="profile-picture"
          loading="lazy"
        />
      </div>
    </div>
  );
};

export default Me;
