import React from "react";
import "./css/Experience.css";

const Experience = ({ role, company, location, period, details, icon }) => {
  return (
    <div className="experience-container">
      <img src={icon} alt={company} className="company-icon" loading="lazy" />
      <div className="experience-info">
        <h2 className="experience-company">{company}</h2>
        <h3 className="experience-role">{role}</h3>
        <p className="experience-location">{location}</p>
        <p className="experience-period">{period}</p>
        {details.map((detail, index) => (
          <p key={index} className="experience-detail">
            {detail}
          </p>
        ))}
      </div>
    </div>
  );
};

export default Experience;
