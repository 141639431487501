import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import { LanguageProvider } from "./context/LanguageContext";
import Me from "./pages/Me";
import Projects from "./pages/Projects";
import Experiences from "./pages/Experiences";
import "./App.css";
import Contact from "./pages/Contact";

function App() {
  return (
    <LanguageProvider>
      <Router>
        <Navbar />
        <main>
          <Routes>
            <Route path="/" element={<Me />} />
            <Route path="/projects" element={<Projects />} />
            <Route path="/experience" element={<Experiences />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
        </main>
        <Footer />
      </Router>
    </LanguageProvider>
  );
}

export default App;
