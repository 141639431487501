import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import "./css/Navbar.css";
import { useLanguage } from "../context/LanguageContext";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { language, toggleLanguage } = useLanguage();
  const [content, setContent] = useState({ navbar: { navLinks: [] } });
  const navbarRef = useRef(null);

  useEffect(() => {
    fetch("/language-mapping.json")
      .then((response) => response.json())
      .then((data) => setContent(data));
  }, [language]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        navbarRef.current &&
        !navbarRef.current.contains(event.target) &&
        isOpen
      ) {
        setIsOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  const toggleMenu = () => setIsOpen(!isOpen);

  const closeMenu = () => setIsOpen(false);

  const { navbar } = content[language] || { navbar: { navLinks: [] } };

  return (
    <nav className="navbar" ref={navbarRef}>
      <div className="logo-and-name-container">
        <div className="logo-container">
          <img
            src="/logo400.png"
            alt={navbar.logoAlt}
            className="logo"
            loading="lazy"
          />
        </div>
        <div className="name">Ferhat Korkmaz</div>
      </div>
      <ul className={`nav-links ${isOpen ? "open" : ""}`}>
        {navbar.navLinks.map((link, index) => (
          <li key={index} onClick={closeMenu}>
            <Link to={link.link} className="nav-link">
              {link.name}
            </Link>
          </li>
        ))}
        {isOpen && (
          <li className="mobile-language-toggle" onClick={toggleLanguage}>
            {language === "en" ? "🇺🇸" : "🇹🇷"}
          </li>
        )}
      </ul>
      <button className="hamburger" onClick={toggleMenu}>
        ☰
      </button>
      <button
        className="language-toggle"
        onClick={toggleLanguage}
        title="Change Language"
      >
        {language === "en" ? "🇺🇸" : "🇹🇷"}
      </button>
    </nav>
  );
};

export default Navbar;
